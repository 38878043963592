const initialState = {
  loading: false,
  freesMinted: 0,
  numMinted: 0,
  whitelisted: false,
  owned: [],
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        freesMinted: action.payload.freesMinted,
        numMinted: action.payload.numMinted,
        whitelisted: action.payload.whitelisted,
        owned: action.payload.owned,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
