// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let name = await store
        .getState()
        .blockchain.smartContract.methods.name()
        .call();
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.SKULLYS_PRICE()
        .call();
      let status = await store
        .getState()
        .blockchain.smartContract.methods.getStatus()
        .call();

      let account = await store.getState().blockchain.account;

      let freesMinted = await store
        .getState()
        .blockchain.smartContract.methods.freeSkullysPerOwner(account)
        .call();

      let numMinted = await store
        .getState()
        .blockchain.smartContract.methods.skullysMintedByOwner(account)
        .call();

      let whitelisted = await store
        .getState()
        .blockchain.smartContract.methods.isOnWhiteList(account)
        .call();

      let bal = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account)
        .call();

      let owned = [];
      for (let i = 0; i < bal; i++) {
        let tokenId = await store
          .getState()
          .blockchain.smartContract.methods.tokenOfOwnerByIndex(account, i)
          .call();
        owned.push(tokenId)
      }

      dispatch(
        fetchDataSuccess({
          name,
          totalSupply,
          cost,
          status,
          freesMinted,
          numMinted,
          whitelisted,
          owned,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
