import MetaMaskOnboarding from '@metamask/onboarding';

import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import SmartContract from "../../contracts/Skullys.json";
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = (skipPermissions) => {
  return async (dispatch) => {
    dispatch(connectRequest());

    const { ethereum } = window;

    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);

        if (!skipPermissions) {
            await ethereum.request({
              method: 'wallet_requestPermissions',
                params: [{eth_accounts: {}}]
            });
        }

      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
          params: [{eth_accounts: {}}]
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        //if (networkId != 4002) { // Fantom Testnet
        if (networkId != 250) { // Fantom Testnet
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{
              chainId: "0xFA",
            }]
          })
        }
        // const NetworkData = await SmartContract.networks[networkId];
        //if (networkId == 4002) { // Fantom Testnet
        //if (ethereum.chainId == 250) { // Fantom Mainnet
        if (networkId == 250) { // Fantom Mainnet
          const SmartContractObj = new Web3EthContract(
            SmartContract,
            "0x25ff0d27395A7AAD578569f83E30C82a07b4ee7d"
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed("Switch network to Fantom"));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
