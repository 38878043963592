// log
import store from "../store";


const fetchInfoRequest = () => {
  return {
    type: "CHECK_INFO_REQUEST",
  };
};

const fetchInfoSuccess = (payload) => {
  return {
    type: "CHECK_INFO_SUCCESS",
    payload: payload,
  };
};

const fetchInfoFailed = (payload) => {
  return {
    type: "CHECK_INFO_FAILED",
    payload: payload,
  };
};

export const fetchInfo = (contract) => {
  return async (dispatch) => {
    dispatch(fetchInfoRequest());
    try {
      let name = await contract.methods.name().call();
      let totalSupply = await contract.methods.totalSupply().call();
      let cost = await contract.methods.SKULLYS_PRICE().call();
      let status = await contract.methods.getStatus().call();

      dispatch(
        fetchInfoSuccess({
          name,
          totalSupply,
          cost,
          status,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchInfoFailed("Could not load non-user info from contract."));
    }
  };
};
