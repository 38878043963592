import React, { useEffect, useState, useRef } from "react";
import {isMobile} from 'react-device-detect';

import Web3 from "web3";

import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchInfo } from "./redux/info/infoActions";

import abi from "./contracts/Skullys.json";

import './css/style.css';

// Load images statically
import ghostly     from "./images/ghostly-img.png";
import skully      from "./images/skully-img.png";
import menu_img    from "./images/menu.png";
import royal_img   from "./images/royalties.png";
import seance_img  from "./images/seance.png";
import token_img   from "./images/token.png";
import vr_img      from "./images/vr.png";
import discord_img from "./images/discord.png";
import twitter_img from "./images/twitter.png";
import deli_img    from "./images/deliland.png";


const MAX_SKULLYS = 1000
const REVEALED = 600
const CONTRACT_ADDR = "0x25ff0d27395A7AAD578569f83E30C82a07b4ee7d"
const MINT_COST = 150
//const IPFS_IMG_BASE = "//ipfs.io/ipfs/bafybeihofiazvsa5pcdnwxwttrigzhqp762w6ddvfmlezmmxp25e6m2wfi/"
const IPFS_IMG_BASE = "//ipfs.io/ipfs/bafybeihofiazvsa5pcdnwxwttrigzhqp762w6ddvfmlezmmxp25e6m2wfi/"
const IPFS_META_BASE = ""

function App() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const info = useSelector((state) => state.info);
    const [claimingNft, setClaimingNft] = useState(false);

    const readOnlyWeb3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools/'))
    const contract = new readOnlyWeb3.eth.Contract(abi, "0x25ff0d27395A7AAD578569f83E30C82a07b4ee7d")
    const fetchInfoAux = () => fetchInfo(contract)

    const claimNFTs = () => {
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mintSkully()
            .send({
                to: CONTRACT_ADDR,
                from: blockchain.account,
                value: blockchain.web3.utils.toWei((MINT_COST).toString(), "ether"),
            })
            .once("error", (err) => {
                console.log(err);
                setClaimingNft(false);
            })
            .then((receipt) => {
                setClaimingNft(false);
                dispatch(fetchInfoAux());
                dispatch(fetchData(blockchain.account));
            });
    };

    const claimNFTsFree = () => {
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mintFreeSkully()
            .send({
                to: CONTRACT_ADDR,
                from: blockchain.account,
            })
            .once("error", (err) => {
                console.log(err);
                setClaimingNft(false);
            })
            .then((receipt) => {
                setClaimingNft(false);
                dispatch(fetchInfoAux());
                dispatch(fetchData(blockchain.account));
            });
    };

    const getInfo = () => {
        dispatch(fetchInfoAux());
        console.log("TS: " + info.totalSupply)
    };
    const getData = () => {
        dispatch(fetchInfoAux());
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    const menuToggle = () => {
        const navlinks = document.querySelector('.nav-links')
        navlinks.classList.toggle('mobile-menu')
    }

    const mintOrConnect = (e) => {
        if (blockchain.connected) {
            e.preventDefault();
            if (data.whitelisted && data.freesMinted == 0) {
                claimNFTsFree();
             } else {
                claimNFTs();
             }
        } else {
            e.preventDefault();
            dispatch(connect(isMobile));
            getData();
        }
    }


    const skullyById = (tokenId, idx) => {
        console.log("TOKEN ID: " + tokenId)
        let imgId = tokenId <= REVEALED ? tokenId : "default"
        console.log("IPFS: " + IPFS_IMG_BASE + imgId)
        return (
        <div className="col" key={idx}>
            <img src={IPFS_IMG_BASE + imgId} alt=""/>
            <h4>{"Skully #" + tokenId.toString()}</h4>
        </div>
        )
    }

    const mySkullys = () => {
        return (
            <div className="row">
                {!blockchain.connected
                    ? <h4>Not connected</h4>
                    : data.owned.length > 0
                        ? data.owned.map(skullyById)
                        : <h4>You are not a member of the cult.</h4>}
            </div>
        )
    }

    return (
        <div className="App">
            <nav className="navbar">
                <h1 className="logo"><a href="./index.html">SKULLYS</a></h1>
                <ul className="nav-links">
                    <li><a href="https://ghostgarden.io">GHOSTLYS</a></li>
                    <li><a href="https://monapizza.io">GALLERY<p className="beta">beta</p></a></li>
                    <li><a href="https://skullys.gitbook.io/the-cult/">ROADMAP</a></li>
                    <li><a href="https://skullys.shop">SHOP</a></li>
                    <li><a href="#" id="connect" className="connect-btn" onClick={(e) => {
                           e.preventDefault();
                           dispatch(connect(isMobile));
                           getData()}}
                        >
                            {blockchain.connected ? blockchain.account :
                                blockchain.errorMsg ? blockchain.errorMsg : "Connect"}
                        </a>
                    </li>
                </ul>
                <img src={menu_img} alt="" className="menu-btn" onClick={menuToggle}/>
            </nav>
            <header>
            <div className="header-content">
                <h1>Join the cult.</h1>
                    <p>{!blockchain.connected ? ""
                        : info.totalSupply < MAX_SKULLYS ? "The gates are open."
                        : "The gates are closed."}
                {data.whitelisted && info.freesMinted == 0 ? " You have one free mint."
                        : ""}
                </p>
                <div>
                    <button href="#" className="mint-btn"
                       onClick={mintOrConnect}
                    >
                        {!blockchain.connected ? "Connect"
                            : claimingNft ? "Minting..."
                            : data.numMinted < 5 ? "Mint"
                            : "You minted your 5"}
                    </button>
                </div>
                <p></p>
                <p>{info.totalSupply + " minted"}</p>
                <div className="progress-bar">
                <div className="progress-bar-fill"></div>
                </div>
                <div>Total Supply of 1,000</div>
                </div>
            </header>

            <section className="wallet">
            <div className="title">
                <h1>YOUR SKULLYS</h1>
            </div>
                {mySkullys()}
            </section>

            <section className="drops">
            <div className="title">
                <h1>MEMBERSHIPS</h1>
            </div>
            <div className="row">
                <div className="col">
                    <img src={ghostly} alt=""/>
                    <h4>GHOSTLYS</h4>
                    <p>Total supply of 8,888 Ghostlys.</p>
                </div>
                <div className="col">
                    <img src={skully} alt=""/>
                    <h4>SKULLYS</h4>
                    <p>Total supply of 1,000 Skullys.</p>
                </div>
            </div>
            </section>
            <section className="roadmap">
            <div className="roadmap-content">
                    <h1>VIRTUAL NFT GALLERY</h1>
                    <p>Visit our gallery to view art from our partners and our own collections. Join the cult to help curate the gallery.</p>
                    <a href="https://monapizza.io" className="btn">Explore Beta</a>
                </div>
                </section>

            <section className="drops">
            <div className="title">
                <h1>TEAM</h1>
            </div>
            <div className="row">
                <div className="col">
                    <img src={IPFS_IMG_BASE + 1} alt=""/>
                    <h4>Funeral</h4>
                </div>
                <div className="col">
                    <img src={IPFS_IMG_BASE + 2} alt=""/>
                    <h4>Larkin</h4>
                </div>
            </div>
            </section>
            <section className="dashboard">
                <div className="dashboard-content">
                    <h4>A PRODUCT OF</h4>
                    <h1>Farmgod DAO</h1>
                    <p>Explore the Farmgod Dashboard to optimize your farming experience on Fantom.</p>
                    <a href="https://farmgod.finance" className="btn">Farmgod Dashboard</a>
                </div>
            </section>
            <section className="footer">
                <div className="social-links">
                    <a href="https://twitter.com/skullysnft"><img src={twitter_img} alt=""/></a>
                    <a href="https://discord.gg/mADYsDXfE8"><img src={discord_img} alt=""/></a>
                    <a href="https://deliland.io"><img src={deli_img} alt=""/></a>
                  </div>
            </section>
        </div>
    );
}

export default App;
